.Box {
  text-align: center;
}

.plane {
  position: relative;
  height: 10px;
  background-color: #888;
  margin: 50px auto;
  transform-origin: left center;
}

.ball {
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: 0;
}

.controls {
  margin-top: 20px;
  text-align: center;
}

.controls label {
  display: block;
  margin: 10px 0;
}

input[type="range"] {
  margin-left: 10px;
}
