.Box1 {
  text-align: center;
  position: relative;
  height: 550px;
}


.ground {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 40%;
  height: 5px;
  left: 30%;
  background-color: green;
}

.object {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 20;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  color: white;
}

.object p {
  font-size: 8px;
  color:black;
  display: inline-block;
  line-height: normal;
  vertical-align:top;
}

.ball {
  background-color: red;
  left: 40%;
}

.feather {
  left: 60%;
  background-image:url('../images/leaf.svg' )
}

.controls {
  margin-top: 50px;
  text-align: left;
  margin-left: 150px;
  font-size: 14px;
}

.controls label {
  display: block;
  margin: 10px 0;
}

input[type="range"],
input[type="number"] {
  margin-left: 10px;
}
